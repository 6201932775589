import '../styles/main.scss';

// Load key if recurring user
const websiteKey = localStorage.websiteKey;
if (!!websiteKey) {
    $('#websitekey').val(websiteKey);
    initCaptcha(websiteKey);
}
$('#saveConfig').on('click', () => {
    var websiteKey = $('#websitekey').val();
    initCaptcha(websiteKey);
});


function initCaptcha(websiteKey) {
    localStorage.websiteKey = websiteKey;
    removeCaptchaScripts();
    loadScriptWithKey(websiteKey);
}

// Append a new recaptcha script with public key
function loadScriptWithKey(websiteKey) {
    var script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + websiteKey;
    script.id = 'googleCaptchaScript';
    script.rel = 'noreferrer';
    script.onload = function () {
        grecaptcha.ready(function() {
            $('#generate').on('click', () => {
                grecaptcha.execute(websiteKey, {
                    action: 'homepage'
                }).then(function(token) {
                    $('#captchahere').val(token);
                });
            });
        });
    };

    document.head.appendChild(script);
}

// Remove all recaptcha scripts
function removeCaptchaScripts() {
    var scripts = document.getElementsByTagName('script');
    scripts.forEach((script) => {
        if (script.src.indexOf('recaptcha') >= 0) {
            document.head.removeChild(script);
        }
    });
}

$('#copyKey').on('click', () => {
    $('#captchahere').select();
    document.execCommand('copy');
});
